<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppCard, {CardSize} from "@/Components/Shared/card/AppCard.vue";
import { useAttrs } from "vue";

export type GridCardSize = "small" | "regular" | "large" | "post" | "side";

type Props = {
    cards: object[];
    size?: GridCardSize;
    showSet?: boolean;
    cardSize?: CardSize
};
const { cards, size = "regular", cardSize = 'regular', showSet = false } = defineProps<Props>();

defineOptions({
    inheritAttrs: false,
});

// classes
const classes: AntlerClasses<Props> = {
    base: "grid gap-2",
    variants: {
        size: {
            side: "grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-2",
            small: "grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8",
            regular: "grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6",
            large: "grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4",
            post: "grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3",
        },
    },
};
const attrs = useAttrs();
const { aClass } = installAntlerComponent(
    "card-grid",
    { size },
    classes,
    attrs,
);
</script>

<template>
    <div :class="aClass()">
        <AppCard
            v-for="card in cards"
            :key="card.id"
            :card="card"
            :size="cardSize"
            :show-set="showSet"
        />
    </div>
</template>
